import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { UserContext } from "./UserContextProvider";

type ThemeContextType = {
    toggleDarkMode: () => void;
    darkModeActivated: boolean;
};

export const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType);

const ThemeContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const userContext = useContext(UserContext);

    const [darkModeActivated, setDarkModeActivated] = useState<boolean>(false);

    const toggleDarkMode = () => {
        setDarkModeActivated(!darkModeActivated);
        userContext.user?.setPreference("darkMode", !darkModeActivated);
    };

    useEffect(() => {
        if (userContext.user) {
            setDarkModeActivated(Boolean(userContext.user?.getPreference("darkMode")));
        }
    }, [userContext.user]);

    return <ThemeContext.Provider value={{ toggleDarkMode, darkModeActivated }}>{children}</ThemeContext.Provider>;
};

export default ThemeContextProvider;
