import { Alert, Snackbar } from "@mui/material";
import React, { createContext, ReactNode, useRef, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";

type AlertContextType = {
    success: (message: string) => void;
    error: (message: string) => void;
    warning: (message: string) => void;
    info: (message: string) => void;
};

export const AlertContext = createContext<AlertContextType>({} as AlertContextType);

const AlertContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [open, setOpen] = useState(false);
    const type = useRef("");
    const text = useRef("");

    const success = (message: string) => {
        type.current = "success";
        text.current = message;
        handleOpen();
    };
    const error = (message: string) => {
        type.current = "error";
        text.current = message;
        handleOpen();
    };
    const warning = (message: string) => {
        type.current = "warning";
        text.current = message;
        handleOpen();
    };
    const info = (message: string) => {
        type.current = "info";
        text.current = message;
        handleOpen();
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getAlert = () => {
        switch (type.current) {
            case "success":
                return (
                    <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" onClose={handleClose}>
                        {text.current}
                    </Alert>
                );
            case "error":
                return (
                    <Alert icon={<ErrorIcon fontSize="inherit" />} severity="error" onClose={handleClose}>
                        {text.current}
                    </Alert>
                );
            case "warning":
                return (
                    <Alert icon={<WarningIcon fontSize="inherit" />} severity="warning" onClose={handleClose}>
                        {text.current}
                    </Alert>
                );
            case "info":
                return (
                    <Alert icon={<InfoIcon fontSize="inherit" />} severity="info" onClose={handleClose}>
                        {text.current}
                    </Alert>
                );
        }
    };

    return (
        <AlertContext.Provider value={{ success, error, warning, info } as AlertContextType}>
            {open ? (
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                    {getAlert()}
                </Snackbar>
            ) : null}

            {children}
        </AlertContext.Provider>
    );
};

export default AlertContextProvider;
