import React, { useContext, useEffect } from "react";
import { UserContext } from "@context/UserContextProvider";
import MainLayout from "@components/layout/MainLayout";
import { Box, Grid, Typography } from "@mui/material";
import FirebaseAuth from "@components/auth/FirebaseAuth";
import { signOut } from "firebase/auth";
import { auth } from "@config/firebase";

const SignoutPage: React.FC = () => {
    const userContext = useContext(UserContext);

    useEffect(() => {
        const logout = () => {
            if (userContext.user) {
                signOut(auth).catch((error) => {
                    console.error(error);
                });
            }
        };

        logout();
    }, [userContext]);

    return (
        <MainLayout>
            <Grid container spacing={2} paddingY={10}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" rowGap={10}>
                        <Typography variant="subtitle1">Session closed</Typography>
                        <FirebaseAuth />
                    </Box>
                </Grid>
            </Grid>
        </MainLayout>
    );
};

export default SignoutPage;
