import { FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

type IEditableTextField = {
    text: string;
    onChange: (editedText: string) => void;
};

const EditableTextField: React.FC<IEditableTextField> = ({ text, onChange }) => {
    const [edit] = useState<boolean>(!text);
    const [editedText, setEditedText] = useState(text || "");

    const onChangeText = (text: string) => {
        onChange(text);
        setEditedText(text);
    };

    useEffect(() => {
        setEditedText(text);
    }, [text]);

    return (
        <FormControl fullWidth>
            <TextField
                style={{ padding: 0 }}
                autoFocus
                contentEditable={edit}
                variant={"standard"}
                value={editedText}
                placeholder="Add a title"
                onChange={(e) => onChangeText(e.target.value)}
                suppressContentEditableWarning={true}
            />
        </FormControl>
    );
};

export default EditableTextField;
