import React from "react";
import HappyWriteImage from "@assets/images/female-happy-typing.png";
import { Box, styled } from "@mui/material";

const Container = styled("div")(({ theme }) => ({
    backgroundImage: `url(${HappyWriteImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "bottom",
    backgroundPositionX: "right",
    height: "100vh",
    backgroundColor: theme.palette.backgroundColor.dark,
}));

const NotesLayout: React.FC<{ children: React.ReactNode }> = (children) => {
    return (
        <Container>
            <Box sx={{ height: "100vh", width: "100hh" }}>{children.children}</Box>
            {/* <Footer /> */}
        </Container>
    );
};

export default NotesLayout;
