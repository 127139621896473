import React from "react";
import MainLayout from "@components/layout/MainLayout";
import { Avatar, Box, Divider, Typography } from "@mui/material";
import MenuSocial from "@components/navbar/social/MenuSocial";
import AvatarImage from "@assets/images/avatar.png";

const AboutPage: React.FC = () => {
    return (
        <MainLayout>
            <Box display="flex" flexDirection="column" height="90%" padding={2}>
                <Box textAlign="center">
                    <Typography variant="subtitle1">About</Typography>
                    <br />
                    <Divider />
                </Box>
                <Box key="body" textAlign="center" paddingX={{ lg: 20 }} paddingY={2}>
                    <Typography variant="body1">
                        Welcome to My Private Library, your ultimate companion for organizing thoughts and ideas. Our
                        intuitive digital notebook allows effortless note-taking, seamless navigation, and creative
                        expression. With a hierarchical structure, easily create pages and subpages to organize your
                        content. Accessible from any device, capture inspiration on the go.
                    </Typography>

                    <Typography variant="body1">
                        Welcome to a new era of organized inspiration and limitless possibilities!
                    </Typography>
                    <br />
                    <br />
                    
                    <br />

                    {/* <Avatar src={AvatarImage} alt="avatar" sx={{ width: 150, height: 150 }} /> */}
                </Box>
                <MenuSocial />
            </Box>
        </MainLayout>
    );
};

export default AboutPage;
