import React, { useContext, useEffect, useState } from "react";
import { Paper, Slide } from "@mui/material";
import { styled } from "@mui/material/styles";
import NotesLayout from "@components/layout/NotesLayout";
import PagesList from "@components/library/PagesList";
import { PageContext } from "@context/PageContextProvider";
import PageView from "@components/library/PageView";

const DEFAULT_LEFT_COLUMN_WIDTH_PERCENTAGE = 20;

const Container = styled("div")(() => ({
    display: "flex",
    justifyContent: "space-bettwen",
    height: "100vh",
}));
const LeftColumn = styled(Paper)(({ theme }) => ({
    flex: 1,
    overflow: "hidden",
    height: "100%",
    paddingX: 10,
    backgroundColor: theme.palette.backgroundColor.light,
    [theme.breakpoints.up("xs")]: {
        position: 'absolute',
        zIndex: 500,
        width: "90%",
    },
    [theme.breakpoints.up("sm")]: {
        position: 'absolute',
        width: "90%",
    },
    [theme.breakpoints.up("md")]: {
        position: 'inherit',
        width: "20%",
        float: 'left'
    },
    [theme.breakpoints.up("lg")]: {
        position: 'inherit',
        width: "30%",
        float: 'left'
    },
}));
const RightColumn = styled(Paper)(({ theme }) => ({
    overflow: "auto",
    height: "100%",
    background: "transparent",
    float: 'right',
    transition: theme.transitions.create(["width", "transform"], {
        duration: theme.transitions.duration.standard,
    }),
    [theme.breakpoints.down("sm")]: {
        display: 'none'
    },
    [theme.breakpoints.up("md")]: {
        display: 'block',
        position: 'inherit'
    },
}));
const RightColumnMobile = styled(Paper)(({ theme }) => ({
    overflow: "auto",
    height: "100%",
    background: "transparent",
    [theme.breakpoints.down("sm")]: {
        position: 'fixed',
        width: `100%`,
    },
    [theme.breakpoints.up("md")]: {
        display: 'none'
    },
}));

const LibraryPage: React.FC = () => {
    const pageContext = useContext(PageContext);
    const [splitterPosition, setSplitterPosition] = useState(DEFAULT_LEFT_COLUMN_WIDTH_PERCENTAGE);

    useEffect(() => {
        if (pageContext.isReadMode()) {
            setSplitterPosition(0);
        } else {
            setSplitterPosition(DEFAULT_LEFT_COLUMN_WIDTH_PERCENTAGE);
        }
    }, [pageContext]);

    return (
        <NotesLayout>
            <Container>
                <Slide direction="right" in={!pageContext.isReadMode()}>
                    <LeftColumn elevation={10}>
                        <PagesList />
                    </LeftColumn>
                </Slide>

                <RightColumn style={{ width: `${100 - splitterPosition}%`, background: "transparent" }}>
                    <PageView page={pageContext.page} />
                </RightColumn>

                <RightColumnMobile >
                    <PageView page={pageContext.page} />
                </RightColumnMobile>
            </Container>
        </NotesLayout>
    );
};

export default LibraryPage;
