import React from "react";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";

const pages = ["about"];

const MenuPages: React.FC = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event: any) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            {pages.map((page, i) => (
                <Button href={page} key={page} onClick={handleCloseNavMenu} sx={{ display: "block" }}>
                    {page}
                </Button>
            ))}
        </Grid>
    );
};
export default MenuPages;
