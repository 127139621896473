export enum Colors {
    PRIMARY = "#E77728",
    SECONDARY = "#EDB230",
    TERTIARY = "#EDB230",
    QUATERNARY = "#F5F5F5",

    PRIMARY_TEXT = "#EDB230",
    SECONDARY_TEXT = "#000",
    TERTIARY_TEXT = "#7D8491",

    BACKGROUND_CLEAR = "#FFF",
    BACKGROUND_DARK = "#000",
    BACKGROUND_GREY = "#F5F5F5",
}
