const Embed = require("@editorjs/embed");
const List = require("@editorjs/list");
const Table = require("@editorjs/table");
const Marker = require("@editorjs/marker");
const Warning = require("@editorjs/warning");
const Code = require("@editorjs/code");
// const LinkTool = require("@editorjs/link");
// const Image = require("@editorjs/image");
const Raw = require("@editorjs/raw");
const Header = require("@editorjs/header");
const Quote = require("@editorjs/quote");
const CheckList = require("@editorjs/checklist");
const Delimiter = require("@editorjs/delimiter");
const InlineCode = require("@editorjs/inline-code");
const SimpleImage = require("@editorjs/simple-image");

export const EDITOR_JS_TOOLS = {
    embed: Embed,
    table: Table,
    marker: Marker,
    list: List,
    warning: Warning,
    code: Code,
    // link: {
    //     class: LinkTool,
    //     config: {
    //         endpoint: 'http://127.0.0.1:5001/my-private-library-f49c0/us-central1/fetchUrl', // Your backend endpoint for url data fetching
    //     }
    // },
    // image: Image,
    raw: Raw,
    header: Header,
    quote: Quote,
    checklist: CheckList,
    delimiter: Delimiter,
    inlineCode: InlineCode,
    simpleImage: SimpleImage,
};

export const EDITOR_JS_VIEW_TOOLS = {
    header: Header,
};
