import React from "react";
import MainLayout from "@components/layout/MainLayout";
import { Box, Typography } from "@mui/material";
import MenuSocial from "@components/navbar/social/MenuSocial";

const ContactPage: React.FC = () => {
    return (
        <MainLayout>
            <Box key="footer">
                <Typography variant="h1">Let's talks</Typography>
            </Box>
            <Box key="footer">
                <MenuSocial />
            </Box>
        </MainLayout>
    );
};

export default ContactPage;
