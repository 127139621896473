import React, { createContext, useEffect, useState } from "react";
import { auth, db } from "@config/firebase";
import LoadingPage from "@pages/LoadingPage";
import User from "@components/types/User";
import { collection, getDoc, getDocs, query, where } from "firebase/firestore";

type UserContextProviderProps = {
    children: React.ReactNode;
};

export type AuthType = {
    user: User;
    accessToken: string;
};

type UserContextType = {
    user: User | null;
    // @Deprecated
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
};

export const UserContext = createContext<UserContextType>({} as UserContextType);

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);

    auth.onAuthStateChanged(async (currentUser) => {
        setLoading(false);
        if (currentUser && !user) {
            let user = await User.loadOrCreate(
                currentUser.uid,
                currentUser.displayName,
                currentUser.email,
                currentUser.photoURL
            );

            setUser(user);
        }
    });

    useEffect(() => {
        if (user) {
            setLoading(false);
        }
    }, [user]);

    const node = loading ? <LoadingPage /> : children;

    return <UserContext.Provider value={{ user, setUser }}>{node}</UserContext.Provider>;
};
