import { Button, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { useState } from "react";
import IPage from "@components/types/IPage";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NotesIcon from "@mui/icons-material/Notes";
import ShortTextIcon from "@mui/icons-material/ShortText";

type IPageToggableItemControl = {
    page: IPage;
    level: number;
    open?: boolean;
};

const PageToggableItemControl: React.FC<IPageToggableItemControl> = ({ page, level }) => {
    const [open, setOpen] = useState(false);

    const subPagesMenu =
        page.subPages && Array.isArray(page.subPages) && page.subPages.length > 0
            ? page.subPages.map((subPage) => {
                  return <PageToggableItemControl key={subPage.getId()} page={subPage} level={level + 1} />;
              })
            : null;

    return (
        <>
            <Tooltip title={page.title} placement="right">
                <ListItemButton
                    sx={{
                        padding: "0 !important",
                        paddingLeft: level * 20,
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                        borderBottomColor: (theme) => theme.palette.neutral.main,
                    }}
                    key={page.getId()}
                    component="a"
                >
                    {level === 0 ? (
                        <NotesIcon color="primary" style={{ width: 15, marginLeft: level * 20, marginRight: 5 }} />
                    ) : (
                        <ShortTextIcon color="primary" style={{ marginRight: 5, width: 15 }} />
                    )}

                    <ListItemText
                        primary={page.title}
                        style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "8   0%",
                        }}
                    />

                    {subPagesMenu ? (
                        <ListItemIcon>
                            <Button
                                variant="text"
                                onClick={() => setOpen(!open)}
                                size="small"
                                style={{ marginLeft: 20, float: "right", width: 25, height: 25, minHeight: 25 }}
                            >
                                {open ? (
                                    <KeyboardArrowDownIcon color="primary" style={{ width: 15 }} />
                                ) : (
                                    <KeyboardArrowRightIcon color="primary" style={{ width: 15 }} />
                                )}
                            </Button>
                        </ListItemIcon>
                    ) : (
                        <></>
                    )}
                </ListItemButton>
            </Tooltip>
            {open ? subPagesMenu : <></>}
        </>
    );
};

export default PageToggableItemControl;
