import BasePage from "./BaseBage";
import IPage from "./IPage";

class SubPage extends BasePage implements IPage {
    parent: IPage;
    index: number;

    constructor(title: string, blocks: string, content: string, parent: IPage, index: number, subPages: SubPage[]) {
        super();

        this.title = title;
        this.blocks = blocks;
        this.content = content || "";
        this.parent = parent;
        this.index = index;

        this.subPages = subPages.length
            ? subPages.map((subPage) => {
                  return new SubPage(subPage.title, subPage.blocks, subPage.content, this, subPage.index, subPage.subPages);
              })
            : [];
    }

    public async save(): Promise<void> {
        return await this.parent.save();
    }

    public async delete(): Promise<void> {
        // return super.delete();
    }

    static createBlank(parent: IPage): IPage {
        return new SubPage("", "{}", "", parent, parent.subPages.length, []);
    }

    addSubPage(subPage: SubPage): void {
        this.subPages.push(subPage);
    }

    getId(): string {
        return `${this.parent.getId()}-${this.index}`;
    }

    toJson(): any {
        return {
            title: this.title,
            blocks: this.blocks,
            content: this.content,
            index: this.index,
            subPages: this.subPages.map((subPage) => {
                return subPage.toJson();
            }),
        };
    }

    getPreviousPage = () => {
        if (this.index) {
            return this.parent.getSubPage(this.index - 1);
        }

        return null;
    };

    getNextPage = () => {
        return this.parent.getSubPage(this.index + 1);
    };

    getBreadcrumbs(): IPage[] {
        const parentBreadcrumbs: IPage[] = this.parent.getBreadcrumbs();
        return parentBreadcrumbs.concat([this]);
    }

    getTitle(): string {
        return this.title ? this.title : "untitled";
    }
}

export default SubPage;
