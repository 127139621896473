import React, { useContext, useState } from "react";
import { Avatar, Button, Divider, Menu, MenuItem, Typography } from "@mui/material";
import { UserContext } from "@context/UserContextProvider";
import LogoutIcon from "@mui/icons-material/Logout";
import ArticleIcon from "@mui/icons-material/Article";
import InfoIcon from "@mui/icons-material/Info";

const MenuUser: React.FC = () => {
    const userContext = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    if (userContext.user) {
        return (
            <>
                <Button
                    variant="text"
                    onClick={(event) => {
                        setOpen(true);
                        setAnchorEl(event.currentTarget);
                    }}
                >
                    <Avatar src={userContext.user?.photoURL || ""} />
                </Button>

                <Menu
                    id="long-menu"
                    MenuListProps={{
                        "aria-labelledby": "small-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => {
                        setOpen(false);
                    }}
                >
                    <MenuItem dense>
                        <Button href="/journals" color="primary" startIcon={<ArticleIcon />}>
                            <Typography variant="body2">Journals</Typography>
                        </Button>
                    </MenuItem>
                    <MenuItem dense>
                        <Button href="/about" color="primary" startIcon={<InfoIcon />}>
                            <Typography variant="body2">About</Typography>
                        </Button>
                    </MenuItem>
                    <Divider orientation="horizontal" />
                    <MenuItem dense>
                        <Button href="/sign-out" color="primary" startIcon={<LogoutIcon />}>
                            <Typography variant="body2">Log out</Typography>
                        </Button>
                    </MenuItem>
                </Menu>
            </>
        );
    }

    return <></>;
};

export default MenuUser;
