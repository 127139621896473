import { Box, Toolbar } from "@mui/material";
import React from "react";
import Logo from "../common/brand/Logo";
import MenuPages from "./pages/MenuPages";
import { styled } from "@mui/material/styles";
import MenuUser from "./user/MenuUser";

const Navbar = () => {
    const Container = styled("footer")(({ theme }) => ({
        backgroundColor: "transparent",
        position: "fixed",
        top: 0,
        width: "100%",
    }));

    return (
        <Container>
            <Toolbar sx={{ justifyContent: "space-between" }}>
                <Box display="flex">
                    <Logo />
                    <MenuPages />
                </Box>
                <MenuUser />
            </Toolbar>
        </Container>
    );
};

export default Navbar;
