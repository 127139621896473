import IBlocks from "./IBlocks";
import SubPage from "./SubPage";

abstract class BasePage {
    title: string;
    blocks: string;
    content: string;
    subPages: SubPage[];

    isEmpty(): boolean {
        return this.title === "" && this.blocks === "{}";
    }

    isBlocksEmpty(): boolean { 
        return this.blocks === "{}";
    }

    isContentEmpty(): boolean { 
        return this.content === "" || this.content === null || this.content === undefined;
    }

    parseBlockToHTML(): string {

        if (this.isEmpty()) { 
            return "";
        }
        
        const blocks = JSON.parse(this.blocks) as IBlocks;

        const content = blocks.blocks.reduce(
            (accumulator: string, current: any) => { 
                if (current.type === "paragraph") { 
                    return accumulator += `<p>${current.data.text}</p>`
                }

                return accumulator;
            }, "");

        // console.log('parseBlockToHTML', content);

        return content;
    }

    public getSubPage(index: number): SubPage | null {
        if (index >= 0 && index < this.subPages.length) {
            return this.subPages[index];
        }

        return null;
    }
}

export default BasePage;
