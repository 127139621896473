import React, { useEffect } from "react";
import * as firebaseui from "firebaseui";
import { auth, googleAuthProvider } from "@config/firebase";

const uiConfig = {
    signInSuccessUrl: "/journals",
    signInFlow: "popup",
    signInOptions: [googleAuthProvider.providerId],
    tosUrl: "/",
    // Privacy policy url/callback.
    privacyPolicyUrl: function () {
        window.location.assign("/");
    },
};

// Initialize the FirebaseUI Widget using Firebase.
const ui = new firebaseui.auth.AuthUI(auth);
// The start method will wait until the DOM is loaded.

const FirebaseAuth: React.FC = () => {
    useEffect(() => {
        ui.start("#firebaseui-auth-container", uiConfig);
    }, []);

    return (
        <>
            <div id="firebaseui-auth-container">
                <div id="sign-in-status"></div>
                <div id="sign-in"></div>
                <pre id="account-details"></pre>
            </div>
        </>
    );
};

export default FirebaseAuth;
