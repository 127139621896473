import React from "react";
import Button from "@mui/material/Button";
import { Avatar, Grid } from "@mui/material";

const MenuSocial: React.FC = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            {/* <Button
                href={"https://www.linkedin.com/in/jimmy-carrera-zambrano-b8b505177/"}
                onClick={handleCloseNavMenu}
                sx={{ display: "block" }}
            >
                <Avatar src={LinkedInIcon} style={{ marginRight: 10, width: "3rem", height: "3rem" }} />
            </Button>
            <Button href={"https://twitter.com/JimmyCarreraZa2"} onClick={handleCloseNavMenu} sx={{ display: "block" }}>
                <Avatar src={TwitterIcon} style={{ marginRight: 10, width: "3rem", height: "3rem" }} alt="twitter" />
            </Button> */}
            {/* <Button href={"/"} onClick={handleCloseNavMenu} sx={{ display: "block" }}>
                <Avatar src={SlackIcon} style={{ marginRight: 10, width: "3rem", height: "3rem" }} />
            </Button> */}
        </Grid>
    );
};
export default MenuSocial;
