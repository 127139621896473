import { styled } from "@mui/material/styles";
import { Box, CircularProgress, Fab, Toolbar, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import IPage from "@components/types/IPage";
import { Save, Close } from "@mui/icons-material";
import { AlertContext } from "@context/AlertContextProvider";
import { PageContext } from "@context/PageContextProvider";
import { LinearProgressBarContext } from "@context/LinearProgressBarContextProvider";
import EditableTextField from "@components/common/form/EditableTextField";
import { useHotkeys } from "react-hotkeys-hook";
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css'

const Background = styled(Box)(({ theme }) => ({
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "rgba(0, 0 , 0, .7 )",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10000,
    [theme.breakpoints.up("xs")]: {
        height: "100%",
        width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
        height: "100%",
        width: "100%",
    },
    [theme.breakpoints.up("md")]: {
        height: "100vh",
        width: "100vw",
    },
    [theme.breakpoints.up("lg")]: {
        height: "100vh",
        width: "100vw",
    },
}));

const CentralContainer = styled("div")(({ theme }) => ({
    border: "1px solid #ccc",
    borderRadius: "10px",
    paddingx: 40,
    backgroundColor: theme.palette.backgroundColor.dark,
    [theme.breakpoints.up("xs")]: {
        height: "98%",
        width: "95%",
    },
    [theme.breakpoints.up("sm")]: {
        height: "85%",
        width: "90%",
    },
    [theme.breakpoints.up("md")]: {
        height: "70%",
        width: "80%",
    },
    [theme.breakpoints.up("lg")]: {
        height: "85%",
        width: "80%",
    },
}));

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ];


    const modules = {
        toolbar: 

        [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
          
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
          
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
          
            ['clean']                                         // remove formatting button
          ]
    };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];


const QuillModal: React.FC<{ open: boolean; page: IPage; onClose: () => void }> = ({ open, page, onClose }) => {
    const [title, setTitle] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const [pageState, setPageState] = useState("Saved");
    const alertContext = useContext(AlertContext);
    const pageContext = useContext(PageContext);
    const progressBarContext = useContext(LinearProgressBarContext);

    useHotkeys("esc", () => {
        onClose();
    });

    const save = () => {
        if (page && title !== "") {
            progressBarContext.show();

            setPageState('Saving');
            page.save()
                .then(() => {
                    pageContext.setPage(page);
                    progressBarContext.show();
                    setPageState('Saved');
                })
                .catch(() => {
                    progressBarContext.show();
                    setPageState('Error :(');
                });
        }
    };

    const onTitleUpdated = (title: string) => {
        page.title = title;
        save();
    };

    const onContentUpdate = (content: string) => { 
        page.content = content;
        setContent(content);
        save();
    }

    useEffect(() => {
        if (page && !page.isEmpty() && !page.isBlocksEmpty() && page.isContentEmpty()) { 
            const content = page.parseBlockToHTML();
            page.content = content;
        }
        
        setTitle(page.title);
        setContent(page.content);

        return () => {
            setTitle("");
            setContent("");
        };
    }, [page]);

    return (
        <>
            {open ? (
                <Background>
                    <CentralContainer>
                        <Box display="flex" flexDirection="column" height={"100%"} justifyContent="space-between">
                            <Toolbar
                                sx={{
                                    backgroundColor: (theme) => theme.palette.backgroundColor.light,
                                    borderTopLeftRadius: 9,
                                    borderTopRightRadius: 9,
                                }}>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" width={"100%"}>
                                    <Box display="flex" flexDirection="column" justifyContent="end">
                                        {pageState === "saving" ? (
                                            <CircularProgress disableShrink sx={{ width: 20, height: 20 }} />
                                        ) : (
                                            <Typography variant="body2">{pageState}</Typography>
                                        )}
                                    </Box>
                                    <Box width="60%" display="flex" flexDirection="column" justifyContent="end">
                                        <EditableTextField
                                            key="header"
                                            text={title}
                                            onChange={(title: string) => {
                                                setTitle(title);
                                                onTitleUpdated(title);
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <Fab size={"small"} color="primary" title="Back" onClick={() => onClose()}>
                                            <Close />
                                        </Fab>
                                    </Box>
                                </Box>
                            </Toolbar>
                            <Box sx={{ overflowY: "auto", height: "85%" }} padding={2}>
                                <ReactQuill id="content" 
                                    value={content || ''} 
                                    onChange={onContentUpdate} 
                                    modules={modules} 
                                    formats={formats}
                                    preserveWhitespace={true}/>
                            </Box>
                        </Box>
                    </CentralContainer>
                </Background>
            ) : (
                <></>
            )}
        </>
    );
};

export default QuillModal;
