import React from "react";
import MainLayout from "@components/layout/MainLayout";
import { Container, Grid, useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";
import LoadingGif from "@assets/images/loading.webp";

const LoadingPage: React.FC = () => {
    const isSmallScreen = useMediaQuery("(max-width:600px)");

    const height = isSmallScreen ? "100%" : "100%";

    const Image = styled("img")({
        maxWidth: "100%",
        maxHeight: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    });

    const ImageContainer = styled(Container)({
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    });

    return (
        <MainLayout>
            <Grid container rowGap={5} spacing={2} paddingY={7} paddingX={3} height={height}>
                <Grid item xs={12} sm={12} md={12} lg={12} height={height}>
                    <ImageContainer>
                        <Image src={LoadingGif} alt="loading" />
                    </ImageContainer>
                </Grid>
            </Grid>
        </MainLayout>
    );
};

export default LoadingPage;
