import { db, auth } from "@config/firebase";
import { addDoc, doc, deleteDoc, collection, updateDoc } from "firebase/firestore";
import IPage from "./IPage";
import SubPage from "./SubPage";
import BasePage from "./BaseBage";
import { debounce } from "@mui/material";

class Page extends BasePage implements IPage {
    id: string;
    uid: string;
    debouncedUpdate: () => Promise<void>;

    constructor(id: string, title: string, blocks: string, content: string, subPages: SubPage[], uid: string) {
        super();

        this.id = id;
        this.title = title;
        this.blocks = blocks;
        this.content = content || "";
        this.uid = uid;

        this.subPages = subPages.length
            ? subPages.map((subPage) => {
                  return new SubPage(subPage.title, subPage.blocks, subPage.content, this, subPage.index, subPage.subPages);
              })
            : [];

        this.debouncedUpdate = debounce(() => {
            return this.update();
        }, 2000);
    }

    static createBlank(): Promise<Page> {
        const uid = auth?.currentUser?.uid;

        if (uid) {
            const newPage = new Page("", "", "{}", "", [], uid);
            return newPage.save().then(() => {
                return newPage;
            });
        }

        throw Error("Unauthenticated");
    }

    addSubPage(subPage: SubPage): void {
        this.subPages.push(subPage);
    }

    public async save(): Promise<void> {
        return this.id === "" ? this.create() : this.debouncedUpdate();
    }

    protected create(): Promise<void> {
        const pagesCollectionRef = collection(db, "pages");
        return addDoc(pagesCollectionRef, { ...this.toJson() })
            .then(({ id }) => {
                this.id = id;
            })
            .catch((error) => {
                throw Error("Error", error);
            });
    }

    protected update(): Promise<void> {
        const data = this.toJson();
        const document = doc(db, "pages", `${this.id}`);

        return updateDoc(document, data).catch((error) => {
            throw Error("Error", error);
        });
    }

    public async delete(): Promise<void> {
        const document = doc(db, "pages", this.id);
        return deleteDoc(document).catch((error) => {
            throw Error("Error", error);
        });
    }

    public getId(): string {
        return this.id;
    }

    public toJson() {
        return {
            id: this.id,
            title: this.title,
            blocks: this.blocks,
            content: this.content,
            subPages: this.subPages.map((subPage) => {
                return subPage.toJson();
            }),
            uid: this.uid,
        };
    }

    public getBreadcrumbs(): IPage[] {
        return [this];
    }

    public getTitle(): string {
        return this.title ? this.title : "untitled";
    }
}

export default Page;
