import React, { createContext, useContext, useEffect, useState } from "react";
import IPage from "@components/types/IPage";
import { UserContext } from "./UserContextProvider";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import Page from "@components/types/Page";
import { db } from "@config/firebase";
import { LinearProgressBarContext } from "./LinearProgressBarContextProvider";

type PageContextProviderProps = {
    children: React.ReactNode;
};

type PageContextType = {
    page: IPage | null;
    library: IPage[];
    setPage: React.Dispatch<React.SetStateAction<IPage | null>>;
    writeMode: React.Dispatch<React.SetStateAction<void>>;
    readMode: React.Dispatch<React.SetStateAction<void>>;
    isReadMode: () => boolean;
};

export const PageContext = createContext<PageContextType>({} as PageContextType);

export const PageContextProvider = ({ children }: PageContextProviderProps) => {
    const userContext = useContext(UserContext);
    const pagesCollectionRef = collection(db, "pages");
    const linearProgressBarContext = useContext(LinearProgressBarContext);
    const [library, setLibrary] = useState<IPage[]>([]);
    const [page, setPage] = useState<IPage | null>(null);
    const [mode, setMode] = useState<string>("write");

    const isReadMode = (): boolean => {
        return mode === "read";
    };
    const writeMode = () => {
        setMode("write");
    };
    const readMode = () => {
        setMode("read");
    };

    useEffect(() => {
        let unsubscribe: any = null;
        const getPages = async () => {
            if (userContext.user) {
                linearProgressBarContext.show();

                const q = query(pagesCollectionRef, where("uid", "==", userContext.user.uid));

                unsubscribe = onSnapshot(q, (data) => {
                    const pages: IPage[] = data.docs.map((document) => {
                        let data = document.data();

                        const subPages = data.subPages ? data.subPages : [];

                        return new Page(document.id, data.title, data.blocks, data.content, subPages, data.uid);
                    });

                    setLibrary(pages);
                    linearProgressBarContext.hide();
                });
            }
        };

        getPages();

        return () => {
            unsubscribe && unsubscribe();
        };
    }, [userContext.user]);

    return (
        <PageContext.Provider value={{ page, library, setPage, writeMode, readMode, isReadMode }}>
            {children}
        </PageContext.Provider>
    );
};
