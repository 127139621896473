import { initializeApp } from "firebase/app";
import { EmailAuthProvider, getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyA-4t7DfgYKDOxjs5gE9TRdLCtnmijXr-k",
    authDomain: "my-private-library-f49c0.firebaseapp.com",
    databaseURL: "https://my-private-library-f49c0-default-rtdb.firebaseio.com",
    projectId: "my-private-library-f49c0",
    storageBucket: "my-private-library-f49c0.appspot.com",
    messagingSenderId: "251708470120",
    appId: "1:251708470120:web:f7164c7d8f5bd62aa25cdd",
    measurementId: "G-QYZX758KYP",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleAuthProvider = new GoogleAuthProvider();
export const emailAuthProvider = new EmailAuthProvider();

export const db = getFirestore(app);
