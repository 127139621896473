import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Fab, Typography, styled } from "@mui/material";
import IPage from "@components/types/IPage";
import Page from "@components/types/Page";
import { PageContext } from "@context/PageContextProvider";
import PageMenuView from "./PageMenuView";
import { Edit, Search, KeyboardDoubleArrowLeft } from "@mui/icons-material";
import PageEditModal from "./PageEditModal";
import Logo from "@components/common/brand/Logo";
import SearchModal from "@components/search/SearchModal";
import { useHotkeys } from "react-hotkeys-hook";
import DarkModeSwitch from "@components/common/DarkModeSwitch";
import QuillModal from "./QuillModal";
  
const ListContainer = styled(Box)(({ theme }) => ({
    overflowX: "hidden",
    overflowY: "auto",
    height: "80%",
    [theme.breakpoints.up("xs")]: {
        padding: 15,
    },
    [theme.breakpoints.up("sm")]: {
        padding: 10,
    },
    [theme.breakpoints.up("md")]: {
        padding: 15,
    },
    [theme.breakpoints.up("lg")]: {
        padding: 20,
    },
}));
const OptionsContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    padding: 5,
    justifyContent: "space-evenly",
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: theme.palette.neutral.main,
    [theme.breakpoints.up("xs")]: {
        padding: 5,
    },
    [theme.breakpoints.up("sm")]: {
        padding: 10,
    },
    [theme.breakpoints.up("md")]: {
        paddingX: 15,
    },
    [theme.breakpoints.up("lg")]: {
        paddingX: 20,
    },
}));

const PagesList: React.FC = () => {
    const pageContext = useContext(PageContext);
    const [pages, setPages] = useState<IPage[]>([]);
    const [openPageEditModal, setOpenPageEditModal] = useState(false);
    const [openSearchModal, setOpenSearchModal] = useState(false);

    const onSelectPage = (page: IPage) => {
        pageContext.setPage(page);
    };

    useHotkeys("ctrl+n", () => {
        createPage();
    });

    useHotkeys("ctrl+f", () => {
        setOpenSearchModal(true);
    });

    const createPage = async () => {
        setOpenPageEditModal(true);
        const newPage = await Page.createBlank();
        pageContext.setPage(newPage);
    };

    useEffect(() => {
        setPages(pageContext.library);
    }, [pageContext.library]);

    return (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
            <Box paddingY={2} display="flex" justifyContent="space-between">
                <Box display="flex" justifyContent="space-between">
                    <Logo />
                    <DarkModeSwitch />
                </Box>
                <Button onClick={() => pageContext.readMode()} size="small">
                    <KeyboardDoubleArrowLeft />
                </Button>
            </Box>
            <ListContainer>
                <Box
                    padding={0}
                    sx={{
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                        borderBottomColor: (theme) => theme.palette.neutral.main,
                    }}
                >
                    <Typography variant="h4">My documents</Typography>
                </Box>
                <Box>
                    {pages.map((page) => {
                        return <PageMenuView key={page.getId()} page={page} level={0} onSelect={onSelectPage} />
                    })}
                </Box>
            </ListContainer>
            <OptionsContainer sx={{ display: pageContext.isReadMode() ? "grid" : "flex" }}>
                <Fab size="small" color="primary">
                    <Search onClick={() => setOpenSearchModal(true)} />
                </Fab>
                <Fab size="small" color="primary" onClick={() => createPage()}>
                    <Edit />
                </Fab>
                {/* <Fab size="small" color="primary" onClick={() => createPage()}>
                    <Settings />
                </Fab> */}
            </OptionsContainer>
            {pageContext.page && openPageEditModal ? (
                <QuillModal
                    open={openPageEditModal}
                    page={pageContext.page}
                    onClose={() => setOpenPageEditModal(false)}
                ></QuillModal>
            ) : (
                <></>
            )}
            {pages ? <SearchModal open={openSearchModal} onClose={() => setOpenSearchModal(false)} /> : <></>}
        </Box>
    );
};

export default PagesList;
