import React, { ReactNode, useContext, useMemo } from "react";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
// @ts-ignore
import Monserrat from "../fonts/montserrat/Montserrat-VariableFont_wght.ttf";
import { Colors } from "./Colors";
import { ThemeContext } from "@context/ThemeContextProvider";

declare module "@mui/material/styles" {
    interface Palette {
        backgroundColor: Palette["primary"];
    }
    interface PaletteOptions {
        backgroundColor: PaletteOptions["primary"];
    }
}

declare module "@mui/material/styles" {
    interface Palette {
        neutral: Palette["primary"];
    }
    interface PaletteOptions {
        neutral: PaletteOptions["primary"];
    }
}

const PrimaryThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const themeContext = useContext(ThemeContext);

    const getTheme = () => {
        return createTheme({
            palette: {
                mode: themeContext.darkModeActivated ? "dark" : "light",
                ...(themeContext.darkModeActivated
                    ? {
                          // Dark mode
                          primary: {
                              main: Colors.PRIMARY,
                          },
                          secondary: {
                              main: Colors.QUATERNARY,
                          },
                          warning: {
                              main: Colors.SECONDARY,
                          },
                          info: {
                              main: "#FFF",
                          },
                          backgroundColor: {
                              contrastText: "rgba(66, 16, 16, 0.4)",
                              dark: "rgb(11, 11, 30, 1)",
                              light: "rgb(21, 21, 60, 1)",
                              main: "#FFF",
                          },
                          neutral: {
                              main: "#CCC",
                          },
                      }
                    : {
                          // Light Mode
                          primary: {
                              main: Colors.PRIMARY,
                          },
                          secondary: {
                              main: Colors.QUATERNARY,
                          },
                          warning: {
                              main: Colors.SECONDARY,
                          },
                          info: {
                              main: "#FFF",
                          },
                          backgroundColor: {
                              contrastText: "rgba(255, 255, 16, 0.4)",
                              dark: "rgb(230, 230, 230, 1)",
                              light: "rgb(245, 245, 245)",
                              main: "rgb(255, 255, 255)",
                          },
                          neutral: {
                              main: "#CCC",
                          },
                      }),
            },
            components: {
                MuiCssBaseline: {
                    styleOverrides: `
                    @font-face {
                        font-family: 'Monserrat';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 400;
                        src: local('Monserrat'), local('Monserrat-Regular'), url(${Monserrat}) format('ttf');
                        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                    }
                `,
                },
                MuiDialogActions: {
                    defaultProps: {
                        style: {
                            padding: "1.5rem",
                        },
                    },
                },
                MuiMenu: {
                    defaultProps: {
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        },
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        elevation: 1,
                    },
                },
                MuiButton: {
                    defaultProps: {
                        style: {
                            paddingLeft: "1.2rem",
                            paddingRight: "1.2rem",
                            paddingTop: ".3rem",
                            paddingBottom: ".3rem",
                        },
                    },
                },
                MuiAvatar: {
                    defaultProps: {
                        style: {
                            margin: "0 auto",
                        },
                    },
                },
                MuiToolbar: {
                    defaultProps: {
                        style: {
                            position: "static",
                            minHeight: "75px",
                        },
                    },
                },
                MuiAccordionDetails: {
                    defaultProps: {
                        style: {
                            borderTop: "1px solid red",
                        },
                    },
                },
                MuiListItemButton: {
                    defaultProps: {
                        style: {
                            padding: 5,
                        },
                    },
                },
                MuiListItemIcon: {
                    defaultProps: {
                        style: { minWidth: 0 },
                    },
                },
                MuiLink: {
                    defaultProps: {
                        style: {
                            whiteSpace: "nowrap",
                            textDecoration: "none",
                            color: "primary",
                        },
                    },
                },
                MuiFab: {
                    defaultProps: {
                        style: {
                            color: "primary",
                            margin: 2,
                        },
                        sx: (theme) => {
                            return {
                                color: theme.palette.backgroundColor.light,
                            };
                        },
                    },
                },
                MuiSpeedDial: {
                    defaultProps: {
                        style: {
                            color: "white",
                        },
                    },
                },
                MuiBreadcrumbs: {
                    defaultProps: {
                        style: {
                            color: "primary",
                        },
                    },
                },
            },
            shape: {
                borderRadius: 3,
            },
            typography: {
                fontFamily: "Monserrat, sans-serif",
                fontSize: 13,
                fontWeightRegular: 400,
                fontWeightMedium: 500,
                fontWeightBold: 700,
                h1: {
                    fontSize: "2rem",
                    fontWeight: 700,
                    textTransform: "capitalize",
                },
                h2: {
                    fontSize: "1.5rem",
                    fontWeight: 700,
                    lineHeight: 1.2,
                    textTransform: "capitalize",
                },
                h3: {
                    fontSize: "1rem",
                    fontWeight: 600,
                    lineHeight: 1.2,
                },
                h4: {
                    fontSize: ".9rem",
                    fontWeight: 400,
                },
                subtitle1: {
                    fontSize: "2.2rem",
                    fontWeight: 700,
                    lineHeight: 1.2,
                },
                subtitle2: {
                    fontSize: "1rem",
                    fontWeight: 600,
                    lineHeight: 1.5,
                },
                body1: {
                    fontWeight: 100,
                    lineHeight: 2,
                },
                body2: {
                    textAlign: "center",
                    fontWeight: 100,
                    lineHeight: 1.5,
                },
                button: {
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    lineHeight: 1,
                },
            },
        });
    };

    const theme = useMemo(() => {
        return getTheme();
    }, [getTheme]);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default PrimaryThemeProvider;
