import MenuUser from "@components/navbar/user/MenuUser";
import { PageContext } from "@context/PageContextProvider";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Toolbar, Typography, styled } from "@mui/material";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

const Container = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down("sm")]: {
        padding: 1
    },
    [theme.breakpoints.up("md")]: {
        padding: 3,
    },
}))

const PageViewHeader: React.FC = () => {
    const pageContext = useContext(PageContext);

    return (
        <Toolbar>
            <Container>
                <Box display="flex" flexDirection={"row"}>
                    {pageContext.isReadMode() ? (
                        <Box
                            sx={{
                                transition: (theme) =>
                                    theme.transitions.create(["display", "block"], {
                                        duration: theme.transitions.duration.standard,
                                    }),
                            }}
                            display="flex">
                            <Button onClick={() => pageContext.writeMode()} size="small">
                                <KeyboardDoubleArrowRight />
                            </Button>
                        </Box>
                    ) : (
                        <></>
                    )}
                    {pageContext.page ? (
                        <>
                            <Box paddingY={1.2} marginLeft={2}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    {pageContext.page.getBreadcrumbs().map((page, k) => {
                                        return (
                                            <Link
                                                key={k}
                                                to=""
                                                onClick={() => pageContext.setPage(page)}
                                                style={{ textDecoration: "none" }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        color: (theme) => theme.palette.primary.main,
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                    }}>
                                                    {page.getTitle().substring(0, 25)}
                                                </Typography>
                                            </Link>
                                        );
                                    })}
                                </Breadcrumbs>
                            </Box>
                        </>
                    ) : (
                        <></>
                    )}
                </Box>
                <Box display="flex">
                    <MenuUser />
                </Box>
            </Container>
        </Toolbar>
    );
};

export default PageViewHeader;
