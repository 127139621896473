import React, { useEffect } from "react";
import { EDITOR_JS_TOOLS } from "@config/EditorTools";
import { createReactEditorJS } from "react-editor-js";
import IBlock from "@components/types/IBlocks";
import { Container } from "@mui/material";

type IEditorView = {
    id: string;
    blocks: IBlock;
};

const ReactEditorJS = createReactEditorJS();

const EditorView: React.FC<IEditorView> = ({ id, blocks }) => {
    const style = {
        padding: "20px",
        border: "1px solid #f1f1f1",
        borderRadius: "10px",
        margin: "0 auto",
    };

    const editorCore = React.useRef<any>();

    const handleInitialize = React.useCallback((instance: any) => {
        editorCore.current = instance;
    }, []);

    useEffect(() => {
        return () => {
            if (editorCore.current) {
                editorCore.current = null;
            }
        };
    }, []);

    return (
        <Container style={style}>
            <ReactEditorJS
                minHeight={20}
                key={id}
                defaultValue={blocks}
                onInitialize={handleInitialize}
                tools={EDITOR_JS_TOOLS}
                readOnly
            />
        </Container>
    );
};

export default EditorView;
