import { AlertContext } from "@context/AlertContextProvider";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useContext } from "react";
import IPage from "@components/types/IPage";

type PageDeleteModalType = {
    page: IPage;
    open: boolean;
    onDismiss: () => void;
    onDelete: () => void;
};

const PageDeleteModal: React.FC<PageDeleteModalType> = ({ page, open, onDismiss, onDelete }) => {
    const alertContext = useContext(AlertContext);

    const deletePage = () => {
        page.delete().then(() => {
            alertContext.success(`${page.title} deleted`);
            onDelete();
        });
    };

    return (
        <Dialog open={open} onClose={() => onDismiss()}>
            <DialogTitle>{"Sure you want to delete this document?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">{page.title}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onDismiss()}>Cancel</Button>
                <Button onClick={deletePage} variant="contained" color="error">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PageDeleteModal;
