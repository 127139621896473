import { DeleteForever } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Dialog,
    DialogContent,
    DialogTitle,
    Fab,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import IPage from "@components/types/IPage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PageDeleteModal from "./PageDeleteModal";

type PageSettingsModalType = {
    page: IPage;
    open: boolean;
    onDismiss: () => void;
};

const PageSettingsModal: React.FC<PageSettingsModalType> = ({ page, open, onDismiss }) => {
    const [expanded, setExpanded] = React.useState("");

    const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : "");
    };

    const [openPageDeleteModal, setOpenPageDeleteModal] = useState(false);

    const onDelete = () => {
        onDismiss();
    };

    return (
        <Dialog open={open} onClose={onDismiss} fullWidth>
            <DialogTitle>
                <Typography variant="body1">{page.title}</Typography>
            </DialogTitle>
            <DialogContent>
                <Accordion expanded={expanded === "general"} onChange={handleChange("general")}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="generalbh-content"
                        id="generalbh-header"
                    >
                        <Typography sx={{ width: "33%", flexShrink: 0 }}>General settings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat. Aliquam eget maximus
                            est, id dignissim quam.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "share"} onChange={handleChange("share")}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="sharebh-content"
                        id="sharebh-header"
                    >
                        <Typography sx={{ width: "33%", flexShrink: 0 }}>Share</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam
                            eros in elit. Pellentesque convallis laoreet laoreet.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <Typography sx={{ width: "33%", flexShrink: 0 }}>Export</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas eros,
                            vitae egestas augue. Duis vel est augue.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography sx={{ width: "33%", flexShrink: 0 }}>Delete</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Delete this document{" "}
                            <Fab color="error" onClick={() => setOpenPageDeleteModal(true)}>
                                <DeleteForever />
                            </Fab>
                        </Typography>
                        <PageDeleteModal
                            open={openPageDeleteModal}
                            page={page}
                            onDismiss={() => setOpenPageDeleteModal(false)}
                            onDelete={onDelete}
                        />
                    </AccordionDetails>
                </Accordion>
            </DialogContent>
        </Dialog>
    );
};

export default PageSettingsModal;
