import React from "react";
import { BrowserRouter } from "react-router-dom";
import ApplicationRoutes from "./ApplicationRoutes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ThemeProvider from "./theme/ThemeProvider";
import ContextContainer from "@context/GlobalContextContainer";
import "firebaseui/dist/firebaseui.css";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

if (process.env.NODE_ENV === "production") {
    disableReactDevTools();
}

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ContextContainer>
                <ThemeProvider>
                    <BrowserRouter>
                        <ApplicationRoutes />
                    </BrowserRouter>
                </ThemeProvider>
            </ContextContainer>
        </LocalizationProvider>
    );
}

export default App;
