import { styled } from "@mui/material/styles";
import { Box, Fab, FormControl, TextField, Toolbar } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import IPage from "@components/types/IPage";
import { Close } from "@mui/icons-material";
import PageMenuView from "@components/library/PageMenuView";
import { PageContext } from "@context/PageContextProvider";
import PageToggableItemControl from "@components/library/PageToggableItemControl";

const Background = styled(Box)(({ theme }) => ({
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "rgba(0, 0 , 0, .7 )",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10000,
    [theme.breakpoints.up("xs")]: {
        height: "100%",
        width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
        height: "100%",
        width: "100%",
    },
    [theme.breakpoints.up("md")]: {
        height: "100vh",
        width: "100vw",
    },
    [theme.breakpoints.up("lg")]: {
        height: "100vh",
        width: "100vw",
    },
}));

const CentralContainer = styled("div")(({ theme }) => ({
    border: "1px solid #ccc",
    borderRadius: "10px",
    paddingx: 40,
    backgroundColor: theme.palette.backgroundColor.dark,
    [theme.breakpoints.up("xs")]: {
        height: "98%",
        width: "95%",
    },
    [theme.breakpoints.up("sm")]: {
        height: "85%",
        width: "90%",
    },
    [theme.breakpoints.up("md")]: {
        height: "70%",
        width: "80%",
    },
    [theme.breakpoints.up("lg")]: {
        height: "85%",
        width: "80%",
    },
}));

const SearchModal: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
    const pageContext = useContext(PageContext);
    const [criteria, setCriteria] = useState<string>("");
    const [pagesList, setPagesList] = useState<IPage[]>(pageContext.library);

    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            onClose();
        }
    };

    document.addEventListener("keydown", handleKeyPress);

    const onSelectPage = () => {
        console.log("onSelect");
    };

    /**
     * Search the list in deep (recursivity?)
     * Generates a map with all matches pages and adds a flag to indicates if its open.
     *
     * If the flag is open, then all the pages above should be open, not the pags bellow.
     *
     * the fllag should be prefectable set in all the pages to avoid spreading search login into the next component level.
     */
    const filter = () => {
        const pages = pageContext.library;
        if (criteria === "") {
            setPagesList(pages);
        } else {
            let result = pages.filter((page) => {
                return page.title?.toLowerCase().includes(criteria.toLowerCase());
            });
            setPagesList(result);
        }
    };

    useEffect(() => {
        filter();
    }, [filter]);

    return (
        <>
            {open ? (
                <Background>
                    <CentralContainer>
                        <Box display="flex" flexDirection="column" height={"100%"} justifyContent="space-between">
                            <Toolbar
                                sx={{
                                    backgroundColor: (theme) => theme.palette.backgroundColor.light,
                                    borderTopLeftRadius: 9,
                                    borderTopRightRadius: 9,
                                }}
                            >
                                <Box display="flex" flexDirection="row" justifyContent="space-between" width={"100%"}>
                                    <Box width="90%" display="flex" flexDirection="column" justifyContent="end">
                                        <FormControl fullWidth>
                                            <TextField
                                                style={{ padding: 0 }}
                                                autoFocus
                                                variant={"standard"}
                                                value={criteria}
                                                placeholder="Search"
                                                onChange={(e) => setCriteria(e.target.value)}
                                                suppressContentEditableWarning={true}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Fab size="small" color="primary" title="Back" onClick={() => onClose()}>
                                        <Close />
                                    </Fab>
                                </Box>
                            </Toolbar>
                            <Box sx={{ overflowY: "auto", height: "87%" }} paddingX={5}>
                                {pagesList.map((page) => {
                                    return <PageToggableItemControl key={page.getId()} page={page} level={0} />;
                                })}
                            </Box>
                        </Box>
                    </CentralContainer>
                </Background>
            ) : (
                <></>
            )}
        </>
    );
};

export default SearchModal;
