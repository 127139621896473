import React, { useContext } from "react";
import { UserContext } from "@context/UserContextProvider";
import { Routes, Route } from "react-router-dom";
import HomePage from "@pages/HomePage";
import LibraryPage from "@pages/LibraryPage";
import AboutPage from "@pages/AboutPage";
import SignOutPage from "@pages/SignOut";
import ContactPage from "@pages/ContactPage";
import LoadingPage from "@pages/LoadingPage";

const ApplicationRoutes = () => {
    const userContext = useContext(UserContext);

    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/journals" element={userContext.user ? <LibraryPage /> : <HomePage />} />
            <Route path="/sign-out" element={userContext.user ? <SignOutPage /> : <HomePage />} />
        </Routes>
    );
};

export default ApplicationRoutes;
