import Navbar from "../navbar/Navbar";
import { FC, useMemo } from "react";
import BackgroundImage from "@assets/images/background.jpg";
import { styled } from "@mui/system";

const MainLayout: FC<{ children: React.ReactNode }> = (children) => {
    const Container = styled("div")(({ theme }) => ({
        background: `url(${BackgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.up("xs")]: {
            height: "100vh",
            width: "100vw",
        },
        [theme.breakpoints.up("sm")]: {
            height: "100vh",
            width: "100vw",
        },
        [theme.breakpoints.up("md")]: {
            height: "100vh",
            width: "100vw",
        },
        [theme.breakpoints.up("lg")]: {
            height: "100vh",
            width: "100vw",
        },
    }));

    const CentralContainer = styled("div")(({ theme }) => ({
        backgroundColor: "rgb(255,255,255, .9)",
        border: "1px solid #ccc",
        borderRadius: "10px",
        paddingx: 40,
        [theme.breakpoints.up("xs")]: {
            height: "98%",
            width: "95%",
        },
        [theme.breakpoints.up("sm")]: {
            height: "85%",
            width: "90%",
        },
        [theme.breakpoints.up("md")]: {
            height: "70%",
            width: "80%",
        },
        [theme.breakpoints.up("lg")]: {
            height: "75%",
            width: "70%",
        },
    }));

    const navbar = useMemo(() => {
        return <Navbar />;
    }, []);

    return (
        <Container>
            {navbar}

            <CentralContainer>{children.children}</CentralContainer>
        </Container>
    );
};

export default MainLayout;
