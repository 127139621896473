import { Box, Typography } from "@mui/material";
import Favicon from "./Favicon";

const Logo = () => {
    return (
        <Box display="flex">
            {/* <Favicon /> */}
            <Typography
                variant="h2"
                noWrap
                component="a"
                href="/"
                sx={{
                    mr: 2,
                    marginLeft: 2,
                    marginTop: 1,
                    fontFamily: "revert-layer",
                    color: (theme) => theme.palette.secondary.dark,
                    textDecoration: "none",
                    textTransform: "none",
                }}
            >
                eJournals
            </Typography>
        </Box>
    );
};

export default Logo;
