import React, { useState, useContext, useEffect } from "react";
import { Box, Grid, Link, SpeedDial, SpeedDialAction, Typography, styled } from "@mui/material";
import IPage from "@components/types/IPage";
import { PageContext } from "@context/PageContextProvider";
import EditorView from "@components/common/form/EditorView";
import { Add, ArrowBack, ArrowForward, Menu, Edit, Delete } from "@mui/icons-material";
import SubPage from "@components/types/SubPage";
import PageSettingsModal from "@components/settings/PageSettingsModal";
import PageEditModal from "./PageEditModal";
import PageDeleteModal from "@components/settings/PageDeleteModal";
import PageViewHeader from "./PageViewHeader";
import { useHotkeys } from "react-hotkeys-hook";
import QuillModal from "./QuillModal";

const PageViewContainer = styled(Box)(({theme}) => ({
    height: "100%",
    paddingInline: 50,
    [theme.breakpoints.down('sm')]: { 
        padding: 10,
    },
    [theme.breakpoints.up('md')]: { 
        paddingX: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
    },
}))

const PageContainer = styled(Box)(({theme}) => ({
    [theme.breakpoints.down('sm')]: { 
        padding: 10
    },
    [theme.breakpoints.up('md')]: { 
        padding: 20,
    },
}))

const PageView: React.FC<{ page: IPage | null }> = ({ page }) => {
    const pageContext = useContext(PageContext);
    const [openPageSettingsModal, setOpenPageSettingsModal] = useState(false);
    const editorCore = React.useRef<any>();
    const [openPageEditModal, setOpenPageEditModal] = useState(false);
    const [openPageDeleteModal, setOpenPageDeleteModal] = useState(false);

    useHotkeys("ctrl+e", () => {
        setOpenPageEditModal(true);
    });
    useHotkeys("ctrl+d", () => {
        setOpenPageDeleteModal(true);
    });

    const editPage = () => {
        setOpenPageEditModal(true);
    };

    const addSubPage = () => {
        if (page) {
            const subPage = new SubPage("", "{}", "", page, page.subPages.length, []);
            page.addSubPage(subPage);
            pageContext.setPage(subPage);
            pageContext.writeMode();
            setOpenPageEditModal(true);
        }
    };

    const onDelete = () => {
        pageContext.setPage(null);
        setOpenPageDeleteModal(false);
    };

    const openSubPage = (subPage: SubPage) => {
        pageContext.setPage(subPage);
    };

    const previousPage = page instanceof SubPage ? page.getPreviousPage() : null;
    const nextPage = page instanceof SubPage ? page.getNextPage() : null;

    const relatedPages =
        previousPage || nextPage ? (
            <Grid
                container
                display="flex"
                alignItems="normal"
                flexDirection="row"
                justifyContent="space-between"
                paddingX={5}
                paddingY={2}
            >
                <Grid item xs={4} style={{ overflow: "hidden" }}>
                    { previousPage &&
                        <>
                            <Link
                                style={{ textAlign: "left", lineBreak: "strict", cursor: "pointer" }}
                                onClick={() => openSubPage(previousPage)}
                            >
                                <Box display="flex">
                                    <ArrowBack style={{ marginRight: 10, marginTop: 4 }} />
                                    <Typography
                                        style={{
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                        }}
                                        variant="body1"
                                    >
                                        {previousPage.getTitle()}
                                    </Typography>
                                </Box>
                            </Link>
                        </>
                    }
                </Grid>
                <Grid item xs={4} style={{ overflow: "hidden", textAlign: "right" }}>
                    { nextPage &&
                        <Link onClick={() => openSubPage(nextPage)} style={{ cursor: "pointer" }}>
                            <Box display="flex" justifyContent="end">
                                <Typography
                                    style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                    }}
                                >
                                    {nextPage.getTitle()}
                                </Typography>
                                <ArrowForward style={{ marginLeft: 10, marginTop: 4 }} />
                            </Box>
                        </Link>
                    }
                </Grid>
            </Grid>
        ) : (
            <></>
        );

    const subPages = page ? (
        <Box display="flex" alignItems="normal" flexDirection="column" textAlign="left" paddingX={2} paddingY={2}>
            {page.subPages.map((subPage: SubPage, i) => {
                return (
                    <Box key={i}>
                        <Link style={{ textAlign: "left", cursor: "pointer" }} onClick={() => openSubPage(subPage)}>
                            <Typography>
                                {i + 1}. {subPage.getTitle()}
                            </Typography>
                        </Link>
                    </Box>
                );
            })}
        </Box>
    ) : (
        <></>
    );

    return (
        <>
            <PageViewContainer>
                <PageViewHeader />

                { page &&
                    <PageContainer>
                        <Box paddingY={2} marginLeft={2} textAlign="center">
                            <Typography color="text.primary" variant="h2">
                                {page.getTitle()}
                            </Typography>
                        </Box>
                        {relatedPages}
                        <Box>
                            { !openPageEditModal && !page.isEmpty() && page.isContentEmpty() &&
                                <EditorView id={page.getId()} blocks={JSON.parse(page.blocks)}></EditorView> 
                            }

                            { !page.isContentEmpty() && 
                                <div style={{paddingInline: 30}}>
                                    <div className="text-md text-white" dangerouslySetInnerHTML={{ __html: page.content }} /> 
                                </div>
                            }
                        </Box>

                        {relatedPages}

                        {subPages}

                        <Box>
                            <SpeedDial
                                ariaLabel="Page menu"
                                icon={<Menu color="info" />}
                                aria-orientation="horizontal"
                                sx={{ position: "absolute", bottom: 50, right: 50, zIndex: 1 }}
                            >
                                <SpeedDialAction
                                    key="delete"
                                    icon={<Delete />}
                                    tooltipTitle="Delete"
                                    onClick={() => setOpenPageDeleteModal(true)}
                                ></SpeedDialAction>
                                {/* <SpeedDialAction
                                    key="settings"
                                    icon={<Settings />}
                                    tooltipTitle="Settings"
                                    onClick={() => setOpenPageSettingsModal(true)}
                                ></SpeedDialAction> */}
                                {/* <SpeedDialAction
                                    key="share"
                                    icon={<Share />}
                                    tooltipTitle="Share"
                                    onClick={() => {}}
                                ></SpeedDialAction> */}
                                {/* <SpeedDialAction
                                    key="print"
                                    icon={<Print />}
                                    tooltipTitle="Export"
                                    onClick={() => {}}
                                ></SpeedDialAction> */}
                                <SpeedDialAction
                                    key="subpage"
                                    icon={<Add />}
                                    tooltipTitle="Add sub page"
                                    onClick={() => addSubPage()}
                                ></SpeedDialAction>
                                <SpeedDialAction
                                    key="edit"
                                    icon={<Edit />}
                                    tooltipTitle="Edit"
                                    onClick={() => editPage()}
                                ></SpeedDialAction>
                            </SpeedDial>
                        </Box>
                    </PageContainer>
                }
            </PageViewContainer>

            { page ? (
                <>
                    { openPageEditModal && 
                        <QuillModal 
                            page={page} 
                            open={openPageEditModal} 
                            onClose={() => setOpenPageEditModal(false)} /> 

                        // <PageEditModal 
                        //     page={page}     
                        //     open={openPageEditModal}
                        //     onClose={() => setOpenPageEditModal(false)} />
                    }
                    <PageDeleteModal
                        open={openPageDeleteModal}
                        page={page}
                        onDelete={onDelete}
                        onDismiss={() => setOpenPageDeleteModal(false)}
                    />
                    <PageSettingsModal
                        open={openPageSettingsModal}
                        page={page}
                        onDismiss={() => setOpenPageSettingsModal(false)}
                    />
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default PageView;
