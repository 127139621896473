import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "@config/firebase";

interface Preferences {
    [key: string]: any;
}

class User {
    uid: string;
    displayName: string;
    email: string;
    photoURL: string;
    private preferences: Preferences;

    constructor(
        uid: string,
        displayName?: string | null,
        email?: string | null,
        photoURL?: string | null,
        preferences?: Preferences
    ) {
        this.uid = uid;
        this.displayName = displayName || "";
        this.email = email || "";
        this.photoURL = photoURL || "";
        this.preferences = preferences || ({} as Preferences);
    }

    static create(
        uid: string,
        displayName: string | null,
        email: string | null,
        photoURL: string | null
    ): Promise<User> {
        return setDoc(doc(db, "users", uid), {
            displayName: displayName,
            email: email,
            photoURL: photoURL,
            preferences: {},
        }).then(() => {
            return new User(uid, displayName, email, photoURL);
        });
    }

    static async loadOrCreate(
        uid: string,
        displayName: string | null,
        email: string | null,
        photoURL: string | null
    ): Promise<User | null> {
        const docRef = doc(db, "users", uid);
        return getDoc(docRef)
            .then((user) => {
                if (user.exists()) {
                    return new User(
                        uid,
                        user.data().displayName,
                        user.data().email,
                        user.data().photoURL,
                        user.data().preferences
                    );
                } else {
                    return this.create(uid, displayName, email, photoURL);
                }
            })
            .catch((e) => {
                console.error(e);
                return null;
            });
    }

    getPreference(key: string): any {
        return this.preferences[key];
    }

    setPreference(key: string, value: any): void {
        this.preferences[key] = value;
        this.save();
    }

    save() {
        const data = {
            uid: this.uid,
            displayName: this.displayName,
            email: this.email,
            photoURL: this.photoURL,
            preferences: this.preferences,
        };
        const document = doc(db, "users", `${this.uid}`);

        return updateDoc(document, data).catch((error) => {
            throw Error("Error", error);
        });
    }
}

export default User;
