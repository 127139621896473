import React from "react";
import MainLayout from "@components/layout/MainLayout";
import { Box, Grid, Paper, Typography, styled, useMediaQuery } from "@mui/material";
import FirebaseAuth from "@components/auth/FirebaseAuth";
import MenuSocial from "@components/navbar/social/MenuSocial";
import HappyWriteImage from "@assets/images/writing-ideas.jpeg";

const Home: React.FC = () => {
    const isSmallScreen = useMediaQuery("(max-width:600px)");

    const height = isSmallScreen ? "100%" : "100%";

    const WrittingIdeasCover = styled(Paper)(() => ({
        backgroundImage: `url(${HappyWriteImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        borderRadius: 180,
        width: 300,
        height: 300,
    }));

    return (
        <MainLayout>
            <Grid container rowGap={5} spacing={2} paddingY={7} paddingX={3} height={height}>
                <Grid item xs={12} sm={12} md={6} lg={6} height={height}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        rowGap={5}
                        textAlign="center"
                    >
                        <Typography variant="h2">My private library</Typography>

                        <WrittingIdeasCover />
                        <Typography variant="body2">
                            Capture, organize, and access all your content effortlessly. <br />
                            Experience the convenience of having your digital notebook always at hand. <br />
                            Start creating and exploring today!
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} padding={0} height={height}>
                    <Box display="flex" flexDirection="column" justifyContent="space-between">
                        <Box key="header" textAlign={"center"}>
                            <Typography variant="h2">Ready to start?</Typography>
                        </Box>
                        <Box key="body" padding={0}>
                            <Box marginY={15} padding={0}>
                                <FirebaseAuth />
                            </Box>
                        </Box>
                        <Box key="footer">
                            <MenuSocial />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </MainLayout>
    );
};

export default Home;
