import React, { ReactNode, useMemo } from "react";
import LinearProgressBarContextProvider from "./LinearProgressBarContextProvider";
import AlertContextProvider from "./AlertContextProvider";
import { UserContextProvider } from "./UserContextProvider";
import { PageContextProvider } from "./PageContextProvider";
import ThemeContextProvider from "./ThemeContextProvider";

const ContextContainer: React.FC<{ children: ReactNode }> = ({ children }) => {
    return useMemo(() => {
        return (
            <UserContextProvider>
                <ThemeContextProvider>
                    <LinearProgressBarContextProvider>
                        <AlertContextProvider>
                            <PageContextProvider>{children}</PageContextProvider>
                        </AlertContextProvider>
                    </LinearProgressBarContextProvider>
                </ThemeContextProvider>
            </UserContextProvider>
        );
    }, [children]);
};

export default ContextContainer;
